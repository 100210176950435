<template>
  <b-card no-body class="mb-1">
    <b-card-header class="p-1" role="tab">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Transferencia bancaria</b-button
      >
    </b-card-header>
    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
      <b-card-body>
        <b-row class="justify-content-center">
          <b-col md="10" class="bank-card">
            <div>
              <img
                src="@/assets/itau.png"
                class="thumbnail"
                width="50px"
                alt=""
              />
              - Transferência Bancária
            </div>
            <div class="d-flex flex-column mt-4">
              <small>Beneficiário:</small>
              <p>
                {{ clientData.unityBankAccount.description }}
              </p>
            </div>
            <div class="d-flex flex-column">
              <small>Conta Corrente:</small>
              <p>
                {{ clientData.unityBankAccount.account }}
              </p>
            </div>
            <div class="d-flex flex-column">
              <small>Agência:</small>
              <p>
                {{ clientData.unityBankAccount.agency }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  computed:{
    clientData() {
      return this.$store.state.checkout.clientData;
    },
    unityData(){
      return this.$store.state.checkout.unityData
    },
  }
};
</script>

<style>
</style>